import React, {forwardRef, useEffect, useState} from "react";
import {Row, Col} from "antd";
import {Link} from "gatsby";
import {BsArrowLeftShort} from "@react-icons/all-files/bs/BsArrowLeftShort";
import {BsArrowRightShort} from "@react-icons/all-files/bs/BsArrowRightShort";
import {PATHS} from "../../../constants";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, LEADTEXT} from "../../../styles/Global";
import {TextField} from "../../../styles/Forms";
import {processImage} from "../../../helpers";
import {GatsbyImage} from "gatsby-plugin-image";
import Underline from "../../section/underline";
import Fuse from "fuse.js"

const BlogArticles = ({data, ...props}, ref) => {

  const [search, setSearch] = useState('');
  const [year, setYear] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [result, setResult] = useState(data.articles.nodes);

  useEffect(() => {
    let options = {
      includeScore: true,
      useExtendedSearch: true,
      keys: ['title', 'created', 'relationships.field_category.name', 'relationships.field_tags.name'],
    }
    let pattern = [];
    const fuse = new Fuse(data.articles.nodes, options);

    if (search) {
      pattern.push({title: search});
    }

    if (year) {
      pattern.push({created: `^${year}`});
    }

    if (category) {
      pattern.push({'relationships.field_category.name': category});
    }

    if (tags) {
      pattern.push({'relationships.field_tags.name': tags});
    }

    if (pattern.length) {
      setResult(fuse.search({
        $and: pattern
      }));
    } else {
      setResult(data.articles.nodes);
    }

  }, [data.articles.nodes, search, year, category, tags]);

  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Filtering justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <TextField
              placeholder="Search articles"
              bordered={false}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
          <Col xs={8} sm={24} md={12} lg={6} xl={6}>
            <TextField
              placeholder="Year"
              bordered={false}
              onChange={(e) => setYear(e.target.value)}
            />
          </Col>
          <Col xs={8} sm={24} md={12} lg={6} xl={6}>
            <TextField
              placeholder="Category"
              bordered={false}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Col>
          <Col xs={8} sm={24} md={12} lg={6} xl={6}>
            <TextField
              placeholder="Tags"
              bordered={false}
              onChange={(e) => setTags(e.target.value)}
            />
          </Col>
        </Filtering>

        <Row  justify="space-between" gutter={{xs: 8, sm: 20, md: 20, lg: 32, xl: 32}}>
          {result ? result.map((node, index) => {
            const item = node.item ? node.item : node;
            const image = processImage(item, 'field_featured_image');
            return (
              <Blog
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <Anchor to={`/blogs${item.path.alias}/`}>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.title}
                  />

                  <BlogTitleWrapper>
                    <BlogTitle>
                        {item.title}
                    </BlogTitle>
                  </BlogTitleWrapper>
                </Anchor>
              </Blog>
            );
          }) : null}
        </Row>

        <Pagination gutter={{xs: 8, sm: 20, md: 20, lg: 32}}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Pager>
              <PageDown>
                <BsArrowLeftShort />
              </PageDown>
              <Page>Page 1</Page>
              <PageUp>
                <BsArrowRightShort />
              </PageUp>
            </Pager>
            <Underline data-sal={null} />
          </Col>
        </Pagination>

      </Container>
    </Wrapper>
  );
};

export default forwardRef(BlogArticles);

const Wrapper = styled(Section)`
  position: relative;
  background: rgba(65, 45, 219, 1);
  height: auto;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`;

const Filtering = styled(Row)`
  width: 100%;
  padding: 40px 0;
`

const Blog = styled(Col)`
  position: relative;
  box-sizing: border-box;
  margin-bottom: 30px;
`

const Anchor = styled(Link)`
  display: block;
  position: relative;
`

const BlogTitleWrapper = styled.div`
  position: absolute;
  padding: inherit;
  bottom: 6px;
  left: 0;
  right: 0;
`;

const BlogTitle = styled.div`
  ${LEADTEXT}
  background-color: rgba(0, 0, 0, .5);
  padding: 20px 40px;
  height: auto;
  width: 100%;
  letter-spacing: 0px;
  color: #f1f0f5;
  text-transform: capitalize;
  
  a {
    color: #fff;
  }
`;

const Pagination = styled(Row)`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
`;

const Pager = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
  color: #fff;
`;

const PageDown = styled.div`
  ${LEADTEXT}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ec3917;
  opacity: .25;
`;

const Page = styled.div`
  ${LEADTEXT}
`;

const PageUp = styled.div`
  ${LEADTEXT}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ec3917;
  opacity: .25;
`;
