import React from "react";
import Index from "../components/blogs";
import {graphql} from "gatsby";

const blogs = (props) => {
  return (
    <>
      <Index {...props} />
    </>
  );
};

export default blogs;

export const postQuery = graphql`
query MyQuery {
  articles: allNodeArticle(skip: 1, sort: {fields: created, order: DESC}) {
    nodes {
      entity_id: drupal_internal__nid
      title
      created(formatString: "YYYY")
      path {
        alias
      }
      relationships {
        field_category {
          drupal_id
          name
        }
        field_tags {
          name
        }
        field_featured_image {
          field_media_image {
            alt
            title
          }
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    aspectRatio: 1.75
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`;