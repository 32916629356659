import React, {forwardRef} from "react";
import styled from "styled-components";
import Section from "../../section";
import Underline from "../../section/underline";
import ReadMore from "../../ReadMore";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {processImage} from "../../../helpers";
import {PATHS, BREAKPOINTS} from "../../../constants";
import {CONTAINER, H2} from "../../../styles/Global";
import {useLatestArticle} from "../../../hooks/useLatestArticle";

const Articles = (props, ref) => {
  const node = useLatestArticle();
  const image = processImage(node, 'field_featured_image');

  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Row>
            <LeftSide>
              <TitleWrapper>
                <Link to={`/blogs${node.path.alias}/`}>
                  <Title>{node.title}</Title>
                </Link>
              </TitleWrapper>
              <ReadArticle to={`/blogs${node.path.alias}/`} textStyle={{color: '#f1f0f5'}}  iconStyle={{color: '#f1f0f5'}}>
                Read Article
              </ReadArticle>
            </LeftSide>
            <RightSide>
              <Underline data-sal={null} />
            </RightSide>
          </Row>
        </Content>
      </Container>
      <Background>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt}
          className="featured"
          objectFit="cover"
          style={{overflow: 'hidden', height: '100%'}}
          loading="lazy"
        />
      </Background>
      <Foreground />
    </Wrapper>
  );
};

export default forwardRef(Articles);

const Wrapper = styled(Section)`
  position: relative;
  background: rgba(65, 45, 219, 0.7);
  height: 60vh;
`;

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Foreground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, #412ddb 0%, #3a28c500 100%) 0% 0% no-repeat padding-box;
`;

const Container = styled.div`
  ${CONTAINER}
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0 40px;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

const LeftSide = styled.div`
  position: relative;
  display: block;
  padding-bottom: 100px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 45%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 30%;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;
  display: block;
`;

const Title = styled.h2`
  ${H2}
  margin: 0;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
`;

const RightSide = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 45%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 30%;
  }
`;

const ReadArticle = styled(ReadMore)`
  text-align: left;
  letter-spacing: 0px;
  color: #f1f0f5;
`;