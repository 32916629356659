import React from "react";
import Articles from "./articles";
import Header from "./header";
import Layout from "../Layout";
import Contact from "../contact";
import styled from "styled-components";

const Index = (props) => {
  return (
    <Layout metatags={{
      title: "JLTech > Come learn with us! Find out what we figured out.",
      desc: `Blogs are fundamental for websites to become successful in search engine and online marketing.  
        JLTech not only craft articles for our clients but we ourselves understand the importance of 
        sharing our opinions and what we have learned doing the things we love.  Check out what we have to say!`,
      keywords: `Why you should start a blog, Why you should have a blog, start a blog, technical blogs, web design blog, 
        web development blog, blog writer near me, blog writers, article writers, content design, content creation, quality content, blog`
    }}>
      <Header />
      <Articles {...props} />
      <Chat />
    </Layout>
  );
};
export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;

